<template>
	<div class="basket">
		<div class="title">{{ $t("cart.name") }}</div>
		<div class="goods">
			<div class="list">
				<div class="all">
					<div :class="`check ${checked.all ? 'checked' : ''}`" @click="
              () => {
                checked.all = !checked.all;
                checkAll();
              }
            ">
						<i class="el-icon-check"></i>
					</div>
					<span>{{ $t("tip.all") }}</span>
				</div>
				<div class="item" v-for="(val, key) in list" :key="key">
					<div class="line"></div>
					<div class="main">
						<div v-if="$store.state.isLogin" @click="check(val.cartId)" :class="`check ${
								checked.list.findIndex((item) => {
								return item == val.cartId;
								}) == -1
								? ''
								: 'checked'
							}`">
							<i class="el-icon-check"></i>
						</div>
						<div v-else @click="check(val.skuId)" :class="`check ${
								checked.list.findIndex((item) => {
								return item == val.skuId;
								}) == -1
								? ''
								: 'checked'
							}`">
							<i class="el-icon-check"></i>
						</div>

						<div class="img">
							<img class="regular" :src="val.cover" />
						</div>
						<div class="info">
							<div class="name">
								<span>{{ val.spuName }}</span>
								<span></span>
							</div>
							<!-- <div class="sub" style="margin-bottom: 16px">{{ val.spuName }}</div> -->
							<div class="sub">
								<span v-if="$store.state.isLogin">
								{{ val.specInfo?val.specInfo.replace(/Talla:/g,""):'' }}
								</span>
								<span v-else>
									{{ val.skus[0].specInfo }}
								</span>
							</div>
							<div class="bottom">
								<div class="price">
									<span>{{ val.num }}</span>
									<span> x </span>
									<span>{{ val.price }}EUR</span>
									<span class="subtotal">{{ strfixed(val.price * val.num) }}EUR</span>
									<!-- <span>{{val.originPrice == "0.00" ? "" : val.originPrice + "€"}}</span> -->
								</div>
								<div class="edit">
									<div class="count">
										<i class="el-icon-minus" @click="change(key, -1)"></i>
										<div class="val">{{ val.num }}</div>
										<i class="el-icon-plus" @click="change(key, 1)"></i>
									</div>
									<div class="btn">
										<!-- <span>{{$t('')}}</span><hr> -->
										<span @click="delItem(key)">{{ $t("address.delete") }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--优惠券-->
				<div class="CouponList">
					<div class="row">
						<div class="col-lg-6" v-for="(item, index) in couponList" :key="item.id">
							<div class="box" @click="useCoupon(item, index)" :class="{ active: current === index }">
								<div :class="item.show?'box1':'box3'">
									<h5>-{{item.amount}}%</h5>
									<p>{{ $t("Coupon.Consumo") }} {{item.minLimit}}€</p>
								</div>
								<div class="box2">
									<button>{{ $t("Coupon.Conseguir") }}</button>
									<p>{{item.startTime}}-{{item.endTime}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="total">
				<div class="totalPrice block">
					<div class="count">
						{{ $t("cart.total") }} {{ checked.list.length }}
					</div>
					<div class="line"></div>
					<div class="item">
						<span style="font-family:Regular">{{ $t("cart.subtotal") }} <span
								style="color: #9d9d9c;font-size: 12px;">(iva
								incluido)</span></span>
						<span style="font-family:Regular">{{ (cart.totalFee) }}€</span>
					</div>
					<div class="item">
						<span style="font-family:Regular">{{ $t("cart.coupon") }}</span>
						<span style="font-family:Regular">-{{ (cart.totalCoupon) }}€</span>
					</div>
					<div class="item">
						<span style="font-family:Regular">{{ $t("tip.postFee") }}</span>
						<span style="font-family:Regular">{{ cart.expressFee }}€</span>
					</div>
					<div class="line" style="margin-top: 20px"></div>
					<div class="item">
						<span style="font-family:Semibold">{{ $t("cart.grandTotal") }}</span>
						<span style="font-family:Semibold">{{ cart.totalAmount }}€</span>
					</div>
					<div class="submit" @click="ensure" style="font-family:Semibold">{{ $t("cart.checkout") }}</div>
				</div>
				<!-- <div class="block">
          <div class="count">{{ $t("tip.supCenter") }}</div>
          <div class="line"></div>
          <div class="item">
            <span>{{ $t("tip.supPhone") }}：000 000 0000</span>
          </div>
          <div class="item">
            <span>{{ $t("tip.m2s") }}：10:00AM-6:00PM</span>
          </div>
        </div> -->
				<div class="block">
					<div class="count">{{ $t("tip.pmethod") }}</div>
					<div class="line"></div>
					<div class="item">
						<span>{{ $t("tip.pmethod_tip") }}</span>
					</div>
					<div class="ways">
						<div class="item">
							<img src="../../assets/icon/visa.png" />
							<span>Visa</span>
						</div>
						<div class="item">
							<img src="../../assets/icon/redsys.png" />
							<span>Redsys</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapState } from 'vuex';
	import Api from "../../api/request.js";
	export default {
		name: "Basket",
		components: {},
		data() {
			return {
				current: "",
				checked: {
					all: false,
					list: [],
				},
				list: [],
				couponList: [],
				choicecoupon: {},
				cart: {
					totalFee: 0,
					minOrderAmount: 0,
					total: 0,
					totalAmount: 0,
					totalRebate: 0,
					totalCoupon: 0,
					expressFee: 0,
				},
			};
		},
		computed: {
			...mapState([ 'cartTemp']),
		},
		watch: {
			"checked.list": {
				handler(res) {
					if (res.length == this.cart.total) {
						this.checked.all = true;
					} else {
						this.checked.all = false;
					}
					this.calculate();
				},
				deep: true,
			},
			list(val) {
				if (val.length !== 0) {
					this.checked.all = true;
					this.checkAll();
				}
			},
			"$store.state.isLogin": {
				handler(res) {
					console.log('--watch----isLogin-----------', res)
					if(res===true){
						console.log('--true111111111111111111111111')
						this.getList();
					}
				}
			}
		},
		created() {

			if(this.$store.state.isLogin){
				this.getList();
			}else{
				this.cart.total = this.cartTemp.length;
				this.list = this.cartTemp;
				console.log('-----no----isLogin------------', this.list)
			}
			
		},
		methods: {
			check(id) {
				//单选
				var index = this.checked.list.findIndex((item) => {
					return item == id;
				});
				if (index == -1) {
					this.checked.list.push(id);
				} else {
					this.checked.list.splice(index, 1);
				}
				console.log('----this.checked-------------------', this.checked)
			},
			checkAll() {
				//全选
				if (this.checked.all) {
					if(this.$store.state.isLogin){
						this.checked.list = this.list.reduce((arr, item) => {
							return (arr = arr.concat([item.cartId]));
						}, []);
					}else{
						this.checked.list = this.list.reduce((arr, item) => {
							return (arr = arr.concat([item.skuId]));
						}, []);
					}
					
				} else {
					this.checked.list = [];
				}
				console.log('----checkAll-------------------', this.checked, this.list)
			},
			//获取优惠券
			getCoupon() {
				Api.Cart.coupon({
					page: 1,
					pageLimit: 999,
				}).then(res => {
					let coupondata = res.data.data.list;
					let total = this.cart.totalAmount
					coupondata.forEach(
                        (ele) => ele.show = parseInt( total * 100) > parseInt(ele.minLimit * 100) ? true : false
                    );
					this.couponList = coupondata
				})
			},
			// 使用优惠券
			useCoupon (data, index) {
				let minLimit = data.minLimit * 100
				let total = this.cart.totalAmount * 100
				this.cart.totalRebate = 0
				this.choicecoupon = {}
				if(total > minLimit){
					this.current = index
					this.choicecoupon = data
				}

				var param = {carts: JSON.stringify(this.checked.list), couponUserId: data.couponUserId, text: 1};
				Api.Order.ensure(param).then((res) => {
					this.cart.totalFee = res.data.data.totalFee;
					this.cart.totalAmount = res.data.data.totalAmount;
					this.cart.totalRebate = res.data.data.totalRebate;
					this.cart.totalCoupon = res.data.data.totalCoupon;
					this.cart.expressFee = res.data.data.expressFee;
					console.log('---使用优惠券-------------------------------', res.data.data)
				})
			},
			//统计
			total (totalAmount, totalRebate){
				let a = ( ( totalAmount * 100) - (totalRebate * 100) ) /100
				return a.toFixed(2)
			},
			// 处理小数点
			strfixed(v){
				let a = ( v * 100) /100
				return a.toFixed(2)
			},
			getList() {
				//拉取列表数据
				//this.$loading.start();
				Api.Cart.list({}).then((res) => {
					this.cart.total = res.data.data.total;
					this.list = res.data.data.list;
					console.log('-------拉取列表数据------------------', this.list)
					this.$bus.$emit("getCartNum");
				});
			},
			update(dataList, key, change) {
				//this.$dialog.loading();
				Api.Cart.update({
					data: JSON.stringify(dataList),
				}).then((res) => {
					this.$dialog.close();
					if (res.data.status == "SUCCESSS") {
						this.getList();
					}else{
						let num = parseInt(this.list[key].num) - change;
						this.list[key].num = num<=1 ? 1 : num
						console.log('失败--------------------', res)
					}
				});
			},
			change(key, change) {
				let num = parseInt(this.list[key].num) + change;
				this.list[key].num = num<=1 ? 1 : num
				if(this.$store.state.isLogin){
					let dataList = [];
					this.list.forEach((item) => {
						dataList.push({
							cart_id: item.cartId,
							sku_id: item.skuId,
							num: item.num
						});
					});
					this.update(dataList, key, change);
				}else{
					this.list[key].spuName = this.list[key].spuName + ' '
					this.cart.totalAmount = 0;
					this.list.map((val) =>  this.cart.totalAmount += (val.price * val.num))
					console.log('--change--------------------', this.cart.totalAmount, this.list[key])
				}
			},
			delItem(key) {
				if(this.$store.state.isLogin){
					this.$dialog.confirm(this.$t("cart.delcart") + "?").then((res) => {
						//this.$dialog.loading();
						Api.Cart.del({
							cart_id: this.list[key].cartId
						}).then((res) => {
							this.$dialog.close();
							if (res.data.status == "SUCCESSS") {
								this.$dialog.notify(this.$t("address.deleteSuccess"));
								this.getList();
							}
						});
					})
				}else{
					this.list.splice(key, 1)
					if(this.list.length<=0){
						this.cart.totalAmount = 0;
					}
				}
			},
			calculate() {
				if (this.checked.list.length == 0) {
					this.cart.totalAmount = 0;
					this.cart.totalRebate = 0;
					this.cart.expressFee = 0;
				} else {
					if(this.$store.state.isLogin){
						var param = {carts: JSON.stringify(this.checked.list),};
						Api.Order.ensure(param).then((res) => {
							if (res.data.status == "SUCCESSS") {
								this.cart.totalFee = res.data.data.totalFee;
								this.cart.totalAmount = res.data.data.totalAmount;
								this.cart.totalRebate = res.data.data.allRebate;
								this.cart.expressFee = res.data.data.expressFee;
								this.getCoupon();
							} else {
								this.$router.push("/Address");
							}
							this.$dialog.close();
						});
					}else{
						this.cart.totalAmount = 0;
						this.list.map((val) => {
							if(this.checked.list){
								let ii = this.checked.list.findIndex((item) => {
									return item == val.skuId
								})
								if(ii>-1){
									this.cart.totalAmount += (val.price * val.num)
								}
							}
						}
						)
						this.cart.totalRebate = 0;
						this.cart.expressFee = 0;
						console.log('--totalAmount--------------------', this.cart.totalAmount)
					}
				}
			},
			ensure() {
				if(this.$store.state.isLogin){
					if (this.checked.list.length == 0) {
						this.$dialog.message("Please select the item to be settled");
					} else {
						var arr = [];
						this.checked.list.map((item) => {
							arr.push(
								this.list.find((res) => {
									return res.cartId == item;
								})
							);
						});
						console.log('----------------', arr)
						sessionStorage.setItem("coupon", JSON.stringify(this.choicecoupon));
						sessionStorage.setItem("cart", JSON.stringify(arr));
						this.$router.push("/Ensure");
					}
				}
				else{
					this.$bus.$emit("login")
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.basket {
		overflow: hidden;
	}

	.check {
		width: 20px;
		height: 20px;
		border: 1px solid #757575;
		margin-right: 20px;
		cursor: pointer;
		text-align: center;
		line-height: 25px;

		&>i {
			opacity: 0;
			font-size: 18px;
			color: #fff;
			font-weight: bolder;
			transform: scale(0.1);
			transition: all 0.2s;
		}

		&.checked {
			background: #000;

			&>i {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	.title {
		font-size: 28px;
		font-weight: 600;
		line-height: 40px;
		margin: 40px 0;
		text-align: center;
	}

	.goods {
		width: 78%;
		margin: 0 auto 213px;
		display: flex;

		.list {
			flex: 1;

			.all {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				&>span {
					font-size: 14px;
					font-weight: 400;
				}
			}

			.item {
				.line {
					height: 1px;
					background: #000;
				}

				.main {
					margin: 40px 0;
					display: flex;

					.img {
						width: 170px;
						height: 230px;
						background: #d8d8d8;
						margin-right: 2%;
					}

					.info {
						flex: 1;
						position: relative;

						.name {
							display: flex;
							justify-content: space-between;
							font-size: 14px;
							font-weight: bold;
							color: #333333;
							line-height: 14px;

							&>span:last-child {
								font-weight: 400;
							}
						}

						.sub {
							font-size: 14px;
							font-weight: 400;
							color: #999999;
							line-height: 20px;
							margin-top: 11px;
						}

						.bottom {
							position: absolute;
							display: flex;
							align-items: center;
							justify-content: space-between;
							left: 0;
							right: 0;
							bottom: 0;

							.price {
								font-size: 18px;
								font-weight: bold;
								color: #000000;
								line-height: 25px;

								&>span{
									font-size: 14px;
									font-weight: 400;
									line-height: 20px;
									margin-left: 6px;
									// text-decoration: line-through;
								}
								.subtotal{
									margin-left:20px;
									font-weight: 600;
									font-size: 20px;
								}
							}

							.edit {
								display: flex;
								align-items: center;

								.count {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 106px;
									height: 30px;
									border: 1px solid #979797;

									&>i {
										font-size: 12px;
										cursor: pointer;
									}

									.val {
										width: 52px;
										font-size: 12px;
										font-weight: 400;
										text-align: center;
									}
								}

								.btn {
									display: flex;
									align-items: center;
									margin-left: 40px;

									&>span {
										font-size: 14px;
										cursor: pointer;

										&:hover {
											font-weight: bold;
										}
									}

									&>hr {
										height: 11px;
										margin: 0 0.5em;
									}
								}
							}
						}
					}
				}
			}
		}

		.total {
			width: 400px;
			margin-left: 30px;
			padding-top: 42px;

			.block {
				padding: 0 20px 20px;
				background: #f2f2f4;

				&.totalPrice {
					// background: #123178;
					// color: #fff;

					.line {
						background: #fff;
					}

					.submit {
						height: 48px;
						background: #ffffff;
						border-radius: 6px;
						font-size: 16px;
						font-weight: 600;
						color: #123178;
						line-height: 48px;
						text-align: center;
						margin-top: 20px;
						cursor: pointer;

						&:hover {
							background-color: #efefef;
						}
					}
				}

				.count {
					height: 60px;
					line-height: 60px;
					font-size: 14px;
					font-weight: 400;
					text-align: center;
					font-weight: bold;
				}

				.line {
					height: 1px;
					background: #000;
				}

				&>.item {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					margin-top: 20px;
					display: flex;
					justify-content: space-between;
				}

				.ways {
					display: flex;
					margin-top: 20px;

					.item {
						display: flex;
						align-items: center;
						margin-right: 40px;

						&>img {
							display: block;
							height: 20px;
							margin-right: 12px;
						}

						&>span {
							font-size: 14px;
							font-weight: 400;
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.row {
		margin: 50px 0;
		display: flex;
		flex-wrap: wrap;
		.col-lg-6 {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 10px;
			.active {
                border: 2px solid red;
            }
			.box {
				margin-right: 10%;
				display: flex;
				padding: 0;
				.box1 {
					width: 48%;
					background-color: #fed100;
					text-align: center;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px 0;
					justify-content: center;
					border-right: 2px dotted #000;
					h5 {
						margin: 0;
						font-size: 24px;
						width: 100%;
					}
					p {
						margin: 0;
						padding: 0;
						font-size: 14px;
					}
				}
				.box2 {
					width: 50%;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px 0;
					justify-content: center;
					border: 1px solid #f2f2f2;
					button {
						width: 100px;
						height: 33px;
						margin: 4px 0;
						border-radius: 30px;
						border: 1px solid #fed100;
						font-size: 12px;
						cursor: pointer;
					}
					p {
						color: #999;
						font-size: 12px;
						margin: 0;
						padding: 0 5px;
					}
				}
				.box3 {
					width: 50%;
					background-color: #ccc;
					text-align: center;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px 0;
					justify-content: center;
					border-right: 2px dotted #000;
				}
			}
		}
	}

	@media screen and (max-width: 500px) {
		.title {
			font-size: 4vw;
			font-weight: 600;
			line-height: 5vw;
			margin: 3vw 0;
			text-align: center;
		}

		.goods {
			width: 94vw !important;
			padding: 0 3vw;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
			.list .item .main{
				display: flex;
				flex-wrap: wrap;
			}
			.list .item .main .img {
				width: 30%;
				height: auto;
			}

			.list .item .main .info {
				width: 40%;
			}

			.list .item .main .info .bottom {
				position: relative;
				justify-content: flex-start;
				display: inline;
			}

			.list .item .main .info .bottom .edit{
				margin-top: 10px;
			}

			.total {
				width: auto;
				margin-left: 0px;
			}

		}
		.row{
			.col-lg-6 {
				flex: 0 0 100%;
				max-width: 100%;
				.box{
					width: 100%;
				}
			}
		}
	}
	
	

</style>
